import React from 'react'
import MainLayout from '../components/layout'
import styled from '@emotion/styled'
import {Seo} from '../components/Seo'

const AboutMeStyles = styled.div`
  padding: 20px;
  height: 100vh;

  .heading {
    color: ${props => props.theme.colors.secondary};
  }
`

export default function About () {
  return <MainLayout>
    <AboutMeStyles>
      <h1 className='heading'>About me</h1>
      <p>
        This person would like to keep an air of <i>mystery</i>, for now.
      </p>
    </AboutMeStyles>
    <Seo path='/about' for='page' />
  </MainLayout>
}
